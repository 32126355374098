<template>
  <section>
    <div class="w-full">
      <DataTable showGridlines :value="contratosStore._paginado_zonificacion.rows" class="text-sm" tableStyle="min-width: 50rem">
        <Column field="code" header="Departamento/Municipios">
          <template #body="slotProps">
            <div class="w-full">
              {{ slotProps.data.zonificaciones[0].nomDpto }}
            <button v-if="slotProps.data.zonificaciones.length" @click="slotProps.data.display_modal = true" class="text-xs mt-1 text-blue-600 flex items-center gap-2">Municipios seleccionados <i class="pi pi-external-link text-xs"></i></button>
            </div>
            <Dialog v-model:visible="slotProps.data.display_modal" modal :style="{ width: '50rem', height: '46.1rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
              <template #header>
                <div class="inline-flex align-items-center justify-content-center gap-2">
                  Grupo del departamento <span class="font-bold">{{ slotProps.data.zonificaciones[0].nomDpto }}</span>
                </div>
              </template>
              <div class="w-full p-2">
                <div style="height: 34rem" class="overflow-y-auto pr-2 w-full">
                  <div class="w-full flex gap-2 items-center justify-between mb-2">
                    <p class="font-medium mb-2">Municipios seleccionados</p>
                    <span class="p-input-icon-left">
                      <i class="pi pi-search" />
                      <InputText  v-model="municipioIngresadoBusqueda" @input="filtrarMunicipios(slotProps.data.zonificaciones, $event.target.value)" class="w-32" placeholder="Buscar" />
                    </span>
                  </div>
                    <div v-for="municipio in municipioIngresadoBusqueda ? municipiosFiltrados
                     : slotProps.data.zonificaciones" :key="municipio.cod_dane">
                      <div class="p-2 border my-1 flex justify-between">
                        <p class="text-xs">
                          {{ municipio.nomMpio }}
                        </p>
                      </div>
                    </div>
                  </div>
              </div>
            </Dialog>
          </template>
        </Column>
        <Column field="FecInicio" header="Fecha inicio"></Column>
        <Column field="FecFin" header="Fecha fin"></Column>
        <Column :style="{ width: '20rem' }" field="code" header="Régimen/Movilidad">
          <template #body="slotprops">
            <div class="w-full grid grid-cols-2">
              <div v-if="slotprops.data.Subsidiado" class="flex gap-2 items-center">
                <i class="pi pi-check text-xs font bold text-green-600"></i>
                <p>Subsidiado</p>
              </div>
              <div v-if="slotprops.data.Contributivo" class="flex gap-2 items-center">
                <i class="pi pi-check text-xs font bold text-green-600"></i>
                <p>Contributivo</p>
              </div>
              <div v-if="slotprops.data.MovSubsidiado" class="flex gap-2 items-center">
                <i class="pi pi-check text-xs font bold text-green-600"></i>
                <p>Mov. Subsidiado</p>
              </div>
              <div v-if="slotprops.data.MovContributivo" class="flex gap-2 items-center">
                <i class="pi pi-check text-xs font bold text-green-600"></i>
                <p>Mov. Contributivo</p>
              </div>
            </div>
          </template>
        </Column>
        <Column field="VlrBase" header="Valor máximo"></Column>
      </DataTable>
      <Paginator :rows="10" @page="onPage()" :totalRecords="contratosStore._paginado_zonificacion.count" :rowsPerPageOptions="[10, 20, 30]"></Paginator>
    </div>
  </section>
</template>
<script>
  import { useContratosStore } from '../../../stores/contratos.store'
  import { ref, computed, onMounted } from 'vue'
  import { useRoute } from 'vue-router'
  export default {
    setup () {
      const contratosStore = useContratosStore()
      const route = useRoute()
      const limit = ref(10)
      const offset = ref(0)
      const municipioIngresadoBusqueda = ref()
      const municipiosFiltrados = ref()
      const params = computed(() => {
        return {
          limit: limit.value,
          offset: offset.value
        }
      })
      const listarZonificaciones = () => {
        contratosStore.paginarZonificacion(route.params.id, params.value)
      }
      const onPage = ({ rows }) => {
        limit.value = rows
        listarZonificaciones()
      }
      const abrirModalMunicipios = (linea) => {
        linea.display_modal = true
      }
      const filtrarMunicipios = (municipios, name) => {
        municipiosFiltrados.value = municipios.filter(a => a.nomMpio.toUpperCase().includes(name.toUpperCase()))
      }
      onMounted(() => {
        listarZonificaciones()
      })
      return {
        contratosStore,
        onPage,
        abrirModalMunicipios,
        filtrarMunicipios,
        municipiosFiltrados,
        municipioIngresadoBusqueda
      }
    }
  }
</script>
